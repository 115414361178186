import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { PermissionsProvider } from './contexts/Permissions/PermissionsContext';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import Home from './components/Home/Home';
import RotaProtegida from './components/RotaProtegida/RotaProtegida';
import NotFound from './components/NotFound/NotFound';
import Dashboard from './components/Dashboard/Dashboard';
import ResetPass from './components/ResetPass/ResetPass';
import NewPass from './components/ResetPass/NewPass';
import PublicParty from './components/PublicParty/PublicParty';
import { lightTheme, darkTheme } from './theme';
import { SpeedInsights } from '@vercel/speed-insights/react';
import Register from './components/Register/Register';
import Settings from './components/Settings/Settings';

const App = () => {
  const [mode, setMode] = useState(localStorage.getItem('themeMode') || 'dark');
  const currentTheme = mode === 'dark' ? darkTheme : lightTheme;

  const toggleTheme = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark';
    setMode(newMode);
    localStorage.setItem('themeMode', newMode);
  };

  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode');
    if (savedMode) {
      setMode(savedMode);
    }
  }, []);

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <SpeedInsights />
      <Router>
        <PermissionsProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/reset-password" element={<ResetPass />} />
            <Route path="/new-password" element={<NewPass />} />

            <Route
              path="/"
              element={
                <RotaProtegida
                  element={<Dashboard toggleTheme={toggleTheme} mode={mode} />}
                />
              }
            >
              <Route index element={<RotaProtegida element={<Home />} />} />
            </Route>

            <Route
              path="/settings"
              element={
                <RotaProtegida
                  element={<Dashboard toggleTheme={toggleTheme} mode={mode} />}
                />
              }
            >
              <Route index element={<RotaProtegida element={<Settings />} />} />
            </Route>

            <Route path="/public/:partyId" element={<PublicParty />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </PermissionsProvider>
      </Router>
    </ThemeProvider>
  );
};

export default App;
