import axios from 'axios';

const getToken = () => localStorage.getItem('token');

const apiClient = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BACK_API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${getToken()}`,
  },
});

/**
 * Busca detalhes de uma festa pelo ID da festa para Publico.
 * @param {string} partyId - O ID da festa.
 * @returns {Promise<Object>} Os detalhes da festa.
 */
export const fetchPartyDetailsPublic = async (partyId) => {
  const response = await apiClient.get(
    `/party/searchPartIdPublic/?id=${partyId}`,
  );
  return response.data;
};

/**
 * Faz o login de um usuário com nome de usuário e senha.
 * @param {string} username - O nome de usuário.
 * @param {string} password - A senha.
 * @returns {Promise<string>} O token de autorização.
 */
export const login = async (username, password) => {
  const response = await apiClient.post('/auth/loginReveller', {
    username,
    password,
  });
  return response?.headers?.authorization;
};

/**
 * Reseta a senha de um usuário usando um token.
 * @param {string} token - O token de redefinição.
 * @param {string} password - A nova senha.
 * @returns {Promise<void>}
 */
export const resetPassword = async (token, password) => {
  await apiClient.post(
    '/auth/reset-password',
    { token, password },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

/**
 * Gera um token de redefinição de senha para um determinado email.
 * @param {string} email - O email do usuário.
 * @returns {Promise<void>}
 */
export const generateResetToken = async (email) => {
  await apiClient.post(
    '/auth/generate-reset-token',
    { email },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );
};

/**
 * Busca as coordenadas de um local usando a API OpenCageData.
 * @param {string} location - O nome do local.
 * @returns {Promise<Object>} As coordenadas do local.
 */
export const fetchCoordinates = async (location) => {
  const response = await axios.get(
    'https://api.opencagedata.com/geocode/v1/json',
    {
      params: {
        q: location,
        key: '7bc93777e0e64f03a6e882c7484b2882',
      },
    },
  );
  return response.data.results[0].geometry;
};

/**
 * Busca a temperatura atual de um local usando a API Open-Meteo.
 * @param {number} latitude - A latitude do local.
 * @param {number} longitude - A longitude do local.
 * @returns {Promise<number>} A temperatura atual do local.
 */
export const fetchTemperature = async (latitude, longitude) => {
  const response = await axios.get('https://api.open-meteo.com/v1/forecast', {
    params: {
      latitude,
      longitude,
      current_weather: true,
    },
  });
  return response.data.current_weather.temperature;
};

/**
 * Registers a new user.
 * @param {Object} userData - The user data.
 * @returns {Promise<Object>} The registered user data.
 */
export const registerUser = async (userData) => {
  const response = await apiClient.post('/auth/registerReveller', userData);
  return response.data;
};

export const addCardToWallet = async (
  userIdOrCpf,
  cardNumber,
  expirationDate,
) => {
  const response = await apiClient.post('/wallet/addCard', {
    userIdOrCpf,
    cardNumber,
    expirationDate,
  });
  return response.data;
};

export const withdrawFromWallet = async (userIdOrCpf, cardNumber, amount) => {
  const response = await apiClient.post('/wallet/withdraw', {
    userIdOrCpf,
    cardNumber,
    amount,
  });
  return response.data;
};

export const getWallet = async (userIdOrCpf) => {
  if (!userIdOrCpf) {
    return;
  }

  const response = await apiClient.get(`/wallet/${userIdOrCpf}`);
  return response.data;
};

export const generateCardQRCode = async (userIdOrCpf) => {
  if (!userIdOrCpf) {
    return;
  }

  const response = await apiClient.get(`/wallet/generateQrCode/${userIdOrCpf}`);
  return response.data;
};

export const transferBalance = async (
  fromUserIdOrCpf,
  toUserIdOrCpf,
  amount,
) => {
  const response = await apiClient.post('/wallet/transfer', {
    fromUserIdOrCpf,
    toUserIdOrCpf,
    amount,
  });

  return response.data;
};

export const fetchPurchaseHistory = async (cpf) => {
  try {
    const response = await apiClient.get('/party/searchSoldsDocument', {
      params: { cpf },
    });
    return response.data.solds;
  } catch (error) {
    throw new Error('Erro ao buscar histórico de compras');
  }
};

export const getUserDetails = async (id) => {
  const response = await apiClient.get(`/user/${id}`);
  return response;
};

export const updateUserDetails = async (id, userData) => {
  const response = await apiClient.put(`/edit/${id}`, userData);
  return response;
};
