import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const checkTokenValidity = () => {
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setTokenValid(false);
        setLoading(false);
        return;
      }

      setTokenValid(true);
    } catch (error) {
      setTokenValid(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, []);

  useEffect(() => {
    const publicRoutes = [
      '/login',
      '/register',
      '/reset-password',
      '/new-password',
      '/public',
    ];
    const isPublicRoute = publicRoutes.some((route) =>
      location.pathname.startsWith(route),
    );
    if (!tokenValid && !isPublicRoute) {
      navigate('/login');
    }
  }, [tokenValid, navigate, location]);

  return (
    <PermissionsContext.Provider
      value={{ tokenValid, loading, setTokenValid, checkTokenValidity }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
