import React, { useEffect, useState } from 'react';
import {
  Paper,
  Grid,
  CircularProgress,
  Button,
  Typography,
  TextField,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Collapse,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { jwtDecode } from 'jwt-decode';
import {
  getWallet,
  transferBalance,
  fetchPurchaseHistory,
} from '../../utils/api';
import { GlobalTitle } from '../../utils/global';

const Home = () => {
  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showBalance, setShowBalance] = useState(true);
  const [openTransferDialog, setOpenTransferDialog] = useState(false);
  const [openCardsDialog, setOpenCardsDialog] = useState(false);
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const [expandedParty, setExpandedParty] = useState('');
  const [transferTo, setTransferTo] = useState('');
  const [transferAmount, setTransferAmount] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    handleDecodeToken();
  }, []);

  const handleDecodeToken = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userIdOrCpf = decodedToken.userId;
        fetchWalletData(userIdOrCpf);
        fetchPurchaseHistoryData(decodedToken.cpf);
      } catch (error) {
        console.error('Erro ao decodificar o token:', error);
      }
    }
  };

  const fetchWalletData = async (userIdOrCpf) => {
    setLoading(true);
    try {
      const response = await getWallet(userIdOrCpf);
      setWallet(response.wallet);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar dados da carteira:', error);
      setLoading(false);
    }
  };

  const fetchPurchaseHistoryData = async (cpf) => {
    try {
      const history = await fetchPurchaseHistory(cpf);
      setPurchaseHistory(history);
      if (history.length > 0) {
        setExpandedParty(history[0].partyId);
      }
    } catch (error) {
      console.error('Erro ao buscar histórico de compras:', error);
    }
  };

  const handleTransfer = async () => {
    setError('');
    setSuccess('');
    try {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      const fromUserIdOrCpf = decodedToken.userId;

      await transferBalance(fromUserIdOrCpf, transferTo, transferAmount);
      setSuccess('Transferência realizada com sucesso.');
      setOpenTransferDialog(false);
      fetchWalletData(fromUserIdOrCpf);
    } catch (error) {
      setError('Erro ao transferir saldo. Por favor, tente novamente.');
    }
  };

  const groupPurchasesByParty = (purchases) => {
    return purchases.reduce((grouped, purchase) => {
      const { partyId, partyName } = purchase;
      if (!grouped[partyId]) {
        grouped[partyId] = {
          partyName,
          purchases: [],
        };
      }
      grouped[partyId].purchases.push(purchase);
      return grouped;
    }, {});
  };

  const handleToggleParty = (partyId) => {
    setExpandedParty((prevPartyId) => (prevPartyId === partyId ? '' : partyId));
  };

  if (loading) {
    return <CircularProgress />;
  }

  const groupedPurchases = groupPurchasesByParty(purchaseHistory);

  const sortedParties = Object.keys(groupedPurchases).sort(
    (a, b) =>
      new Date(groupedPurchases[b].purchases[0].soldDate.split(',')[0]) -
      new Date(groupedPurchases[a].purchases[0].soldDate.split(',')[0]),
  );

  return (
    <Box sx={{ padding: '20px' }}>
      <GlobalTitle title="Página Inicial" />
      <Paper
        elevation={3}
        sx={{ padding: '15px', borderRadius: '10px', marginBottom: '20px' }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5">Saldo da Carteira</Typography>
            <IconButton onClick={() => setShowBalance(!showBalance)}>
              {showBalance ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {showBalance
                ? wallet
                  ? `R$ ${wallet.balance}`
                  : 'Saldo não disponível'
                : '***'}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              gap: '10px',
            }}
          >
            <Button
              variant="outlined"
              startIcon={<CreditCardIcon />}
              onClick={() => setOpenCardsDialog(true)}
            >
              Ver Cartões
            </Button>
            <Button
              variant="outlined"
              startIcon={<TransferWithinAStationIcon />}
              onClick={() => setOpenTransferDialog(true)}
            >
              Transferir Saldo
            </Button>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              onClick={() => handleDecodeToken()}
            >
              Atualizar Saldo
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ padding: '15px', borderRadius: '10px' }}>
        <Typography variant="h6">Histórico de Compras</Typography>
        <Divider sx={{ margin: '10px 0' }} />
        {sortedParties.length > 0 ? (
          sortedParties.map((partyId, index) => (
            <Paper key={index} sx={{ padding: '10px', marginBottom: '10px' }}>
              <Typography
                variant="subtitle1"
                onClick={() => handleToggleParty(partyId)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {groupedPurchases[partyId].partyName}
                {expandedParty === partyId ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </Typography>
              <Collapse in={expandedParty === partyId}>
                <List>
                  {groupedPurchases[partyId].purchases.map((purchase, idx) => (
                    <ListItem key={idx}>
                      <ListItemText
                        primary={`${purchase.itemSold} - Qtd: ${purchase.quantitySold}`}
                        secondary={`Data: ${purchase.soldDate} - Valor: R$ ${purchase.value}`}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Paper>
          ))
        ) : (
          <Typography variant="body1">Nenhuma compra encontrada.</Typography>
        )}
      </Paper>

      {/* Dialog para Transferir Saldo */}
      <Dialog
        open={openTransferDialog}
        onClose={() => setOpenTransferDialog(false)}
      >
        <DialogTitle>Transferir Saldo</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="transferTo"
              label="Transferir para (ID ou CPF)"
              name="transferTo"
              autoFocus
              value={transferTo}
              onChange={(e) => setTransferTo(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="transferAmount"
              label="Quantidade"
              name="transferAmount"
              type="number"
              value={transferAmount}
              onChange={(e) => setTransferAmount(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            {success && (
              <Typography color="success" variant="body2">
                {success}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTransferDialog(false)}>Cancelar</Button>
          <Button onClick={handleTransfer} variant="outlined" color="primary">
            Transferir
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog para Ver Cartões */}
      <Dialog open={openCardsDialog} onClose={() => setOpenCardsDialog(false)}>
        <DialogTitle>Cartões</DialogTitle>
        <DialogContent>
          <List>
            {wallet && wallet.cards.length > 0 ? (
              wallet.cards.map((card, index) => (
                <ListItem
                  key={index}
                  sx={{ padding: '10px', borderBottom: '1px solid #ccc' }}
                >
                  <ListItemText
                    primary={`Cartão ${index + 1}`}
                    secondary={card.cardNumber}
                  />
                </ListItem>
              ))
            ) : (
              <Typography variant="body1">Nenhum cartão cadastrado.</Typography>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCardsDialog(false)}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
