import React, { useEffect, useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  CircularProgress,
  Typography,
} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { getUserDetails, updateUserDetails } from '../../utils/api';

const Settings = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cel, setCel] = useState('');
  const [cpf, setCpf] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const userId = jwtDecode(localStorage.getItem('token')).userId;

  useEffect(() => {
    fetchUserDetails(userId);
  }, [userId]);

  const fetchUserDetails = async (id) => {
    setLoading(true);
    try {
      const response = await getUserDetails(id);
      const user = response.data;
      setName(user.name);
      setEmail(user.email);
      setCel(user.cel);
      setCpf(user.cpf);
      setLoading(false);
    } catch (error) {
      console.error('Erro ao buscar detalhes do usuário:', error);
      setLoading(false);
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await updateUserDetails(userId, { name, email, cel, cpf });
      setSuccess('Informações atualizadas com sucesso.');
      setLoading(false);
    } catch (error) {
      setError('Erro ao atualizar informações. Por favor, tente novamente.');
      setLoading(false);
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Configurações do Usuário
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleUpdate}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Nome Completo"
              name="name"
              autoComplete="name"
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="cel"
              label="Celular"
              name="cel"
              autoComplete="cel"
              value={cel}
              onChange={(e) => setCel(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="cpf"
              label="CPF"
              name="cpf"
              autoComplete="cpf"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            {success && (
              <Typography color="success" variant="body2">
                {success}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Atualizar'}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Settings;
