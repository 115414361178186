import React, { useState } from 'react';
import {
  Button,
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../utils/api';
import './Register.css';
import Logo from '../../imgs/logoDark.png';
import Copyright from '../Copyright/Copyright';

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cel, setCel] = useState('');
  const [cpf, setCpf] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [openTermsDialog, setOpenTermsDialog] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');

    if (!acceptedTerms) {
      setError('Você deve aceitar os termos de uso.');
      return;
    }

    setLoading(true);

    try {
      const response = await registerUser({
        username,
        password,
        name,
        email,
        cel,
        cpf,
      });
      const token = response.data.token;
      if (token) {
        localStorage.setItem('token', token);
        navigate('/');
      } else {
        throw new Error('Token não encontrado na resposta');
      }
    } catch (error) {
      setError('Erro ao registrar. Por favor, tente novamente.');
      setLoading(false);
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage:
            'url(https://source.unsplash.com/random?electronic-music)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          animation: 'slide 20s linear infinite',
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={Logo} alt="Logo" />
          <Box
            component="form"
            noValidate
            sx={{ mt: 1 }}
            onSubmit={handleRegister}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nome de Usuário"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              label="Nome Completo"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="email"
              label="Email"
              type="email"
              id="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="cel"
              label="Celular"
              id="cel"
              value={cel}
              onChange={(e) => setCel(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="cpf"
              label="CPF"
              id="cpf"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={acceptedTerms}
                  onChange={(e) => setAcceptedTerms(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2">
                  Eu li e aceito os{' '}
                  <Button
                    onClick={() => setOpenTermsDialog(true)}
                    variant="text"
                    color="primary"
                  >
                    termos de uso
                  </Button>
                </Typography>
              }
            />
            {error && (
              <Typography color="error" variant="body2">
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="outlined"
              color="primary"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : 'Registrar'}
            </Button>
            <Copyright />
          </Box>
        </Box>
      </Grid>

      <Dialog open={openTermsDialog} onClose={() => setOpenTermsDialog(false)}>
        <DialogTitle>Termos de Uso</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            {/* Substitua este texto pelo conteúdo do arquivo de texto dos termos de uso */}
            Estes são os termos de uso. Por favor, leia atentamente antes de
            aceitar.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTermsDialog(false)} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Register;
