import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';
import { LoadFull } from '../../utils/global';

const RotaProtegida = ({ element }) => {
  const { loading, tokenValid, checkTokenValidity } = usePermissions();

  useEffect(() => {
    checkTokenValidity();
  }, [checkTokenValidity]);

  if (loading) {
    return <LoadFull />;
  }

  if (!tokenValid) {
    return <Navigate to="/login" />;
  }

  return element;
};

export default RotaProtegida;
