import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from '../../contexts/Permissions/PermissionsContext';

const Logout = () => {
  const { setTokenValid } = usePermissions();
  const navigate = useNavigate();

  useEffect(() => {
    const handleLogout = () => {
      localStorage.removeItem('token');
      setTokenValid(false);
      navigate('/login');
    };

    handleLogout();
  }, [setTokenValid, navigate]);

  return null;
};

export default Logout;
